var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#ffffff", height: "100%" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("PREPARE")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "div",
            { staticClass: "formMain" },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c("div", { staticClass: "formTopic" }, [_vm._v("基本信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标题", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  readonly: _vm.readonly,
                                  placeholder: "请填写标题",
                                },
                                model: {
                                  value: _vm.addForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "title", $$v)
                                  },
                                  expression: "addForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "单位名称",
                                prop: "contractName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  readonly: _vm.readonly,
                                  placeholder: "请填写单位名称",
                                },
                                model: {
                                  value: _vm.addForm.contractName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "contractName", $$v)
                                  },
                                  expression: "addForm.contractName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "发布时间", prop: "releaseTime" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择发布时间",
                                  readonly: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.releaseTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "releaseTime", $$v)
                                  },
                                  expression: "addForm.releaseTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目名称", prop: "prjName" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "请填写项目名称",
                                  readonly: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.prjName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "prjName", $$v)
                                  },
                                  expression: "addForm.prjName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "措施状态",
                                prop: "warningState",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "未实施",
                                    disabled: _vm.notWarning,
                                    border: "",
                                  },
                                  model: {
                                    value: _vm.addForm.warningState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "warningState", $$v)
                                    },
                                    expression: "addForm.warningState",
                                  },
                                },
                                [_vm._v("未实施")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "已实施",
                                    disabled: _vm.yesWarning,
                                    border: "",
                                  },
                                  model: {
                                    value: _vm.addForm.warningState,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "warningState", $$v)
                                    },
                                    expression: "addForm.warningState",
                                  },
                                },
                                [_vm._v("已实施")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.readonly == false,
                              expression: "readonly == false",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预警项" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectEarlyProject()
                                    },
                                  },
                                },
                                [_vm._v("关联本项目预警项")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "预警处置措施", prop: "remark" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写预警处置措施内容",
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: "3",
                                  "show-word-limit": "",
                                  readonly: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "remark", $$v)
                                  },
                                  expression: "addForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预警处置附件",
                                prop: "filesMeetting",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: {
                                    uoloadSty: _vm.showImg,
                                    disUoloadSty: _vm.noneDealingReport,
                                    "upload-disabled": _vm.readonly,
                                  },
                                  attrs: {
                                    disabled: _vm.readonly,
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    "file-list": _vm.addForm.filesMeetting,
                                    headers: _vm.headers,
                                    "on-preview": _vm.handleFilePreview,
                                    "on-success": (
                                      response,
                                      file,
                                      fileList
                                    ) => {
                                      return _vm.uploadSuccess(
                                        response,
                                        file,
                                        fileList,
                                        "filesMeetting"
                                      )
                                    },
                                    "on-remove": (file, fileList) => {
                                      return _vm.handleRemove(
                                        file,
                                        fileList,
                                        "filesMeetting"
                                      )
                                    },
                                    "on-exceed": _vm.uploadExceed,
                                    "before-upload": _vm.handleBeforeUpload,
                                    limit: "10",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { size: "small" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2",
                                      }),
                                      _vm._v(" 上传文件"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "预览",
                    visible: _vm.dialogVisible,
                    "append-to-body": "",
                    top: "5vh",
                    "custom-class": "customDialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.uploadType) + "\n      "),
                  _vm.uploadType == "img"
                    ? _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: { src: _vm.dialogImageUrl, alt: "" },
                      })
                    : _vm._e(),
                  _vm.uploadType == "video"
                    ? _c("video", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          autoplay: "",
                          src: _vm.dialogVideoUrl,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: "附件预览",
                modal: true,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showFileDialog,
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showFileDialog = $event
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showFileDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "预警项目信息",
                visible: _vm.foreWarningDislog,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.foreWarningDislog = $event
                },
              },
            },
            [
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "gridHeadLayout",
                        attrs: { "search-columns": _vm.searchColumns },
                        on: {
                          "grid-head-search": _vm.loadForeWarningData,
                          "grid-head-empty": _vm.gridHeadEmpty,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.warningTableData,
                    "header-cell-style": {
                      background: "#fafafa",
                      color: "#555",
                    },
                    border: "",
                    height: _vm.tableHeight,
                  },
                  on: { "selection-change": _vm.waringSelectChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      label: "序号",
                      width: "60",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "预警信息标题",
                      width: "320",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sender",
                      align: "center",
                      label: "预警发布单位",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pubTime",
                      align: "center",
                      label: "预警发布时间",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startTime",
                      align: "center",
                      label: "预警开始时间",
                      width: "165",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endTime",
                      align: "center",
                      label: "预警结束时间",
                      width: "165",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "severity",
                      label: "预警严重等级",
                      width: "165",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "severityColor",
                      align: "center",
                      label: "预警严重等级颜色",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tableBottom" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "sizes,prev, pager, next",
                      "current-page": _vm.forePage.jgbcurrpage,
                      "page-sizes": [20, 50, 100, 200],
                      "pager-count": _vm.forePage.currentPage,
                      "page-size": _vm.forePage.pageSize,
                      total: _vm.forePage.total,
                    },
                    on: {
                      "size-change": _vm.foreSizeChange,
                      "current-change": _vm.foreCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tableBottom" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.earlyComfirm()
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.earlyCancel()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }