<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave('PREPARE')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="120px"
        >
          <div class="formTopic">基本信息</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="标题" prop="title">
                <el-input
                  v-model="addForm.title"
                  :readonly="readonly"
                  placeholder="请填写标题"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="单位名称" prop="contractName">
                <el-input
                  v-model="addForm.contractName"
                  :readonly="readonly"
                  placeholder="请填写单位名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发布时间" prop="releaseTime">
                <el-date-picker
                  v-model="addForm.releaseTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="请选择发布时间"
                  :readonly="readonly"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="项目名称" prop="prjName">
                <el-input
                  placeholder="请填写项目名称"
                  v-model="addForm.prjName"
                  class="input-with-select"
                  :readonly="readonly"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="措施状态" prop="warningState">
                <el-radio
                  v-model="addForm.warningState"
                  label="未实施"
                  :disabled="notWarning"
                  border
                  >未实施</el-radio
                >
                <el-radio
                  v-model="addForm.warningState"
                  label="已实施"
                  :disabled="yesWarning"
                  border
                  >已实施</el-radio
                >
              </el-form-item>
            </el-col>

            <el-col :span="8" v-show="readonly == false">
              <el-form-item label="预警项">
                <el-button type="primary" @click="selectEarlyProject()"
                  >关联本项目预警项</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="预警处置措施" prop="remark">
                <el-input
                  placeholder="请填写预警处置措施内容"
                  type="textarea"
                  maxlength="500"
                  v-model="addForm.remark"
                  rows="3"
                  show-word-limit
                  :readonly="readonly"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="预警处置附件" prop="filesMeetting">
                <el-upload
                  :disabled="readonly"
                  :class="{
                    uoloadSty: showImg,
                    disUoloadSty: noneDealingReport,
                    'upload-disabled': readonly,
                  }"
                  action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  :file-list="addForm.filesMeetting"
                  :headers="headers"
                  :on-preview="handleFilePreview"
                  :on-success="
                    (response, file, fileList) => {
                      return uploadSuccess(
                        response,
                        file,
                        fileList,
                        'filesMeetting'
                      );
                    }
                  "
                  :on-remove="
                    (file, fileList) => {
                      return handleRemove(file, fileList, 'filesMeetting');
                    }
                  "
                  :on-exceed="uploadExceed"
                  :before-upload="handleBeforeUpload"
                  limit="10"
                >
                  <el-button size="small"
                    ><i class="el-icon-upload2"></i> 上传文件</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <el-dialog
        title="预览"
        :visible.sync="dialogVisible"
        append-to-body
        top="5vh"
        custom-class="customDialog"
        v-if="dialogVisible"
      >
        {{ uploadType }}
        <img
          style="width: 100%; height: 100%; object-fit: contain"
          :src="dialogImageUrl"
          alt=""
          v-if="uploadType == 'img'"
        />
        <video
          style="width: 100%; height: 100%; object-fit: contain"
          autoplay
          :src="dialogVideoUrl"
          v-if="uploadType == 'video'"
          controls="controls"
        ></video>
      </el-dialog>
      <el-dialog
        v-dialog-drag
        title="附件预览"
        :modal="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showFileDialog"
        width="70%"
      >
        <iframe
          ref="fileIframe"
          :src="fileUrl"
          frameborder="0"
          style="width: 100%; height: 500px"
        ></iframe>
        <span slot="footer">
          <el-button size="small" @click="showFileDialog = false"
            >取消</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        title="预警项目信息"
        :visible.sync="foreWarningDislog"
        width="80%"
      >
        <el-container>
          <el-main>
            <grid-head-layout
              ref="gridHeadLayout"
              :search-columns="searchColumns"
              @grid-head-search="loadForeWarningData"
              @grid-head-empty="gridHeadEmpty"
            ></grid-head-layout>
          </el-main>
        </el-container>

        <el-table
          :data="warningTableData"
          style="width: 100%"
          :header-cell-style="{ background: '#fafafa', color: '#555' }"
          border
          :height="tableHeight"
          @selection-change="waringSelectChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column type="index" align="center" label="序号" width="60">
          </el-table-column>

          <el-table-column prop="title" label="预警信息标题" width="320">
          </el-table-column>

          <el-table-column
            prop="sender"
            align="center"
            label="预警发布单位"
            width="200"
          >
          </el-table-column>

          <el-table-column
            prop="pubTime"
            align="center"
            label="预警发布时间"
            width="200"
          >
          </el-table-column>

          <el-table-column
            prop="startTime"
            align="center"
            label="预警开始时间"
            width="165"
          >
          </el-table-column>

          <el-table-column
            prop="endTime"
            align="center"
            label="预警结束时间"
            width="165"
          >
          </el-table-column>

          <el-table-column prop="severity" label="预警严重等级" width="165">
          </el-table-column>

          <el-table-column
            prop="severityColor"
            align="center"
            label="预警严重等级颜色"
          >
          </el-table-column>
        </el-table>

        <div class="tableBottom">
          <el-pagination
            background
            layout="sizes,prev, pager, next"
            @size-change="foreSizeChange"
            @current-change="foreCurrentChange"
            :current-page="forePage.jgbcurrpage"
            :page-sizes="[20, 50, 100, 200]"
            :pager-count="forePage.currentPage"
            :page-size="forePage.pageSize"
            :total="forePage.total"
          >
          </el-pagination>
        </div>

        <div class="tableBottom">
          <el-button type="primary" @click="earlyComfirm()">确定</el-button>

          <el-button @click="earlyCancel()">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";

import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";

import {
  dbtmeteorologicalwarningPage,
  dbtwarningSumbit,
  dbtwarningDetail,
} from "../../api/dbtEarlyWarningDisposal/index";

import { mapGetters } from "vuex";
import { getDeptTree } from "@/api/system/dept";
export default {
  components: {
    HeadLayout,
    ProjectSelectUser,
  },
  props: {
    type: {
      type: String,
      default: () => {
        return "view";
      },
    },
    id: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      readonly: false,
      isCompany: false,
      addForm: {
        title: "", // 标题
        contractName: "", // 单位名称
        releaseTime: "", // 发布时间
        prjName: "", // 项目名称
        prjId: "", // 项目ID
        remark: "", // 预计处置措施说明
        warningId: 0, // 预警表ID
        warningState: "", // 措施状态
        deptCode: "",
        userName: "",
        userId: "",
        companyName: "",
        companyId: undefined,
        filesMeetting: [],
        status: 0,
        createUserName: "", // 创建人姓名
      },
      rules: {
        title: [
          { required: true, message: "请输入标题名称！", trigger: "blur" },
        ],
        contractName: [
          { required: true, message: "请输入单位名称！", trigger: "blur" },
        ],
        releaseTime: [
          { required: true, message: "请输入发布时间！", trigger: "blur" },
        ],
        prjName: [
          { required: true, message: "请输入项目名称！", trigger: "blur" },
        ],
        warningState: [
          { required: true, message: "请选择措施状态！", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入项目预警措施！", trigger: "blur" },
        ],
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "", // 预览图片路径
      dialogVideoUrl: "", // 预览视频路径
      uploadType: "img", // 默认是图片 img video 是视频的查看方式
      propsHttp: {
        url: "link",
        name: "name",
        res: "data",
      },
      deptShow: false,
      showFileDialog: false,
      fileUrl: "",
      showImg: true, //默认展示
      noneUploadImgVideo: false, //
      noneUploadFile: false,
      noneDealingReport: false,
      noneReportDocUrl: false,
      dealingReport: [],
      reportDocUrl: [],
      foreWarningDislog: false, // 选择预警项目
      warningTableData: [],
      warningSelectData: [], // 现在引入值数据源
      forePage: {
        // 预警项目分页信息
        pageSize: 20,
        currentPage: 1,
        total: 0,
        jgbcurrpage: 1,
      },
      loading: false,
      tableHeight: "24vw",
      notWarning: false,
      yesWarning: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "updata"].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }
      let laryScreen = "";
      if (this.id != "") {
        laryScreen = "true";
      } else {
        laryScreen = this.$route.query.largeScreen;
      }

      console.info(laryScreen);

      if (laryScreen != "true") {
        result.push({
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        });
      }

      return result;
    },

    searchColumns() {
      return [
        {
          label: "标题",
          prop: "title",
          span: 3,
          placeholder: "请输入预警标题",
        },
        {
          label: "预警单位名称",
          prop: "contractName",
          span: 3,
          placeholder: "请输入预警单位名称",
        },
      ];
    },
  },
  mounted() {
    this.init();
    console.log(this.type, this.id);
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init() {
      console.log("this.userInfo", this.userInfo);
      let that = this;
      var userInfo = this.userInfo;
      let type = "";
      let formId = "";
      if (this.id != "") {
        type = this.type;
        formId = this.id;
      } else {
        type = this.$route.query.type;
        formId = this.$route.query.id;
      }
      this.formType = type;
      this.readonly = type == "view";
      this.headTitle = "项目预警处置措施";

      this.formId = formId;

      this.addForm.createUserName = userInfo.user_name; // 发布人名称
      this.addForm.userName = userInfo.user_name; // 用户名称
      this.addForm.userId = userInfo.user_id; // 用户id

      if (this.formType == "updata" || this.formType == "view") {
        this.getDetails(formId, function () {});
      } else if (this.formType == "add") {
        this.addForm.releaseTime = dateFormat(
          new Date(),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
    },
    headSave(status) {
      this.saveOrSubmitFor(status);
    },
    // 保存提交改变状态
    saveOrSubmitFor(type) {
      let that = this;
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveLoading = true;
          // this.addForm.draft = 0;
          // if (
          //   (this.formType == "add" && type == "SUBMIT" && this.formId) ||
          //   (type == "PREPARE" && this.formId) ||
          //   this.formType == "edit" ||
          //   this.formType == "investigate"
          // ) {
          //   this.addForm.id = this.formId;
          // }

          const params = JSON.parse(JSON.stringify(this.addForm));
          // params.filesUser = JSON.stringify(params.filesUser);
          params.filesMeetting = JSON.stringify(params.filesMeetting);

          dbtwarningSumbit(params)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                this.$message.error(msg);
              }
              this.saveLoading = false;
            })
            .catch((err) => {});
        }
      });
    },

    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 获取详情
    getDetails(id, cb) {
      let that = this;
      var userInfo = that.userInfo;
      let createUser = userInfo.id;
      dbtwarningDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          const { data } = res.data;

          Object.keys(this.addForm).forEach((key) => {
            if (key == "filesMeetting") {
              var _value = data[key] ? data[key] : "[]";
              this.addForm[key] = JSON.parse(_value);
            } else {
              this.addForm[key] = data[key];
            }
          });

          if (this.formType == "view") {
            if (this.addForm.warningState == "未实施") {
              this.yesWarning = true;
            } else if (this.addForm.warningState == "已实施") {
              this.notWarning = true;
            }
          }

          if (!id) {
            this.addForm.createUserName = this.userInfo.real_name;
            this.addForm.releaseTime = dateFormat(
              new Date(),
              "yyyy-MM-dd hh:mm:ss"
            );
            delete that.addForm.id;
          } else {
            this.addForm.id = id;
          }

          if (cb && typeof cb == "function") cb.call(this);
        }
      });
    },
    // 上传成功
    uploadSuccess(response, file, fileList, fieldName) {
      if (fieldName == "filesMeetting") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            item.response.data.url = item.response.data.link;
            item.response.data.name = item.response.data.originalName;
            return item.response.data;
          } else {
            return item;
          }
        });
        let data = response.data;
        data.url = response.data.link;
        if (this.isJudgeVideo(data)) {
          // 视频附件，获取第一帧画面作为 封面展示
          this.getVideoCover(data);
        }
      } else {
        // fieldName 表单中向后端传递的字段名
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            return {
              url: item.response.data.link,
              link: item.response.data.link,
              name: item.response.data.originalName,
              attachId: item.response.data.attachId,
              domain: item.response.data.domain,
            };
          } else {
            return item;
          }
        });
      }
    },

    // 判断文件是否为video
    isJudgeVideo(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "mp4" || fileName == "MP4") {
        //视频附件，获取第一帧画面作为 封面展示
        return true;
      } else {
        return false;
      }
    },
    /**
     * 获取视频第一帧作为回显封面
     */
    getVideoCover(file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      file.url = require("@/assets/images/videoLoading.gif"); //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
    },
    // 上传失败回调
    handleError() {},
    // 文件预览
    handlePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "gif"
      ) {
        this.dialogVisible = true;

        this.uploadType = "img";
        this.dialogImageUrl = file.link;
      }
    },
    // 删除文件
    handleRemove(file, fileList, fieldName) {
      if (fieldName == "filesUser" || fieldName == "filesMeetting") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      } else {
        this[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      }
      // if (fileList.length == 0) {
      //   this.$refs.addForm.validateField("fileList");
      // }
    },
    // 文件上传超出限制回调
    uploadExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {},
    // 图片视频上传前的回调
    uploadBeImgVideofore(file) {
      const isJPG = file.type === "image/jpg" || "image/png";
      let maxNum = 5;
      const isLt2M = file.size / 1024 / 1024 < maxNum;

      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式;");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M;");
      }
      return isJPG && isLt2M;
    },

    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.addForm.validateField("fileList");
      }
    },
    handleFilePreview(file) {
      // 附件点击事件
      // debugger
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "gif" ||
        fileName == "mp4" ||
        fileName == "MP4"
      ) {
        this.dialogVisible = true;
        // 如果是视频
        if (fileName == "mp4" || fileName == "MP4") {
          this.uploadType = "video";
          this.dialogVideoUrl = file.link;
        } else {
          this.uploadType = "img";
          this.dialogImageUrl = file.link;
        }
      } else {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL +
          "?url=" +
          encodeURIComponent(Base64.encode(file.url));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      }
    },
    selectEarlyProject() {
      // 选择预警项目

      let that = this;

      that.foreWarningDislog = true;

      that.loadForeWarningData();
    },

    loadForeWarningData(params) {
      // 加载预警项目列表数据源

      if (!params) params = {};

      let that = this;
      let data = {
        current: that.forePage.currentPage,
        size: that.forePage.pageSize,
        relation: 1,
        ...params,
      };

      that.loading = true;

      dbtmeteorologicalwarningPage(data).then((res) => {
        console.info(res);
        that.warningTableData = res.data.data.records;
        that.forePage.total = res.data.data.total;

        that.loading = false;
      });
    },
    waringSelectChange(obj) {
      // 预警项目选择信息

      this.warningSelectData = obj;
    },
    foreSizeChange() {},
    foreCurrentChange(val) {
      // 分页触发器
      this.forePage.jgbcurrpage = val;
      this.forePage.currentPage = this.forePage.jgbcurrpage;
      this.loadForeWarningData();
    },
    gridHeadEmpty(searchForm) {
      // 清空
      this.forePage.currentPage = 1;
      this.forePage.jgbcurrpage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.loadForeWarningData();
    },

    earlyComfirm() {
      // 确认事件

      var that = this;

      if (
        that.warningSelectData.length > 1 ||
        that.warningSelectData.length == 0
      ) {
        that.$message({
          message: "气象预警只能引入一条数据！",
          type: "warning",
        });

        return false;
      }

      console.info(that.warningSelectData);

      that.addForm.title = that.warningSelectData[0].title + "-处置措施"; // 标题

      that.addForm.contractName = that.warningSelectData[0].sender; // 预警单位

      that.addForm.prjName = that.warningSelectData[0].prjName; // 预警项目名称

      that.addForm.prjId = that.warningSelectData[0].prjId; // 预警项目ID

      that.addForm.warningId = that.warningSelectData[0].id; // 预警表ID

      that.warningTableData = [];

      that.foreWarningDislog = false; // 关闭遮罩
    },

    earlyCancel() {
      // 取消事件

      let that = this;

      that.warningTableData = [];

      that.foreWarningDislog = false; // 关闭遮罩
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.formTopic {
  // margin: 0 12px;
  margin-bottom: 12px;

  // width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.disUoloadSty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }

  ::v-deep .el-upload__tip {
    display: none;
  }

  ::v-deep .el-upload--text {
    display: none;
  }
}

::v-deep.upload-disabled .el-upload--picture-card {
  display: none !important;
}

::v-deep.upload-disabled .el-upload--text {
  display: none !important;
}

.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
}
</style>
